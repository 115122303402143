/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
/* body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */
/* body {
    margin: 0;  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 14px;} */

    body {
        font-family: Calibri !important;
        font-size: 16px !important;
        margin: 0px !important;
    }
    
    h1, h2, h3, h4, h5, h6 {
        font-family: Calibri !important;
    }
    h3 {
         color:#FF6218;
    }
    html {
        /*background-color: #D8E4ED !important;*/
        background-color:white;
    }
    
    /* Set padding to keep content from hitting the edges */
    .body-content {
    
        min-height: 500px !important;
        background-color: transparent !important;
    }



    .divIndexBackground {
      position: relative !important;
  }
  
  .divIndexBackground:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0.4;
      background-image: url('assets/Images/TOPS/TOPS - Home Screen - Background - Gray.png');
      background-repeat: no-repeat;
      background-position: 50% 0;
      -ms-background-size: cover;
      -o-background-size: cover;
      -moz-background-size: cover;
      -webkit-background-size: cover;
      background-size: cover;
  }
  .text-center {
      text-align: center;
  }
  .divIndexBackground h2 {
      color: #3cb6ce;
  }
  /* Override the default bootstrap behavior where horizontal description lists 
     will truncate terms that are too long to fit in the left column 
  */
  .dl-horizontal dt {
      white-space: normal;
  }
  /* Set width on the form input elements since they're 100% wide by default 
  input,
  select,
  textarea {
      /*max-width: 280px;
  
  }*/
  
  label {
      font-weight: normal;
  }
  
  .divHeaderContainer {
      /*background-color:#3CB6CE;
      height:100px;
      padding-top: 15px;*/
      padding-left: 0px;
      padding-right: 0px;
      margin-left: 0px;
      margin-right: 0px;
  }
  
  .hrAppLine {
      border-style: solid;
      border-width: 2px;
      margin-top: 5px;
      margin-bottom: 1px;
      color: #0294B5;
  }
  
  .divAppTitle {
      padding-top: 25px;
      padding-left: 0px;
      padding-right: 0px;
  }
  
  .jumbotronCustom {
      background: #D8E4ED;
  }
  
  .form-signin {
      max-width: 300px;
      margin-left: 40px;
  }
  
  .help-block {
      margin-bottom:0px;
  }
  
  .home-wall {
      margin-top: 20px;
      margin-bottom: 20px;
      height: 410px;
      border-top: 12px solid #CBC7BF;
      border-bottom: 24px solid #CBC7BF;
  }
     
  .home-wall2 {
      margin-top: 20px;
      margin-bottom: 20px;
      height: 410px;
      border-top: 12px solid #FF6218;
      border-bottom: 24px solid #CBC7BF;
      position: relative;
  }
  
      .home-wall2 img {
          width: 100%;
          height: 50px;
          bottom:0;
          position:absolute;
      }
  
  .no-margin {
      margin: 0px;
  }
  
  .no-padding {
      padding: 0px !important;
  }
  
  .placeholder {
      color: #aaa;
  }
  
  .site-footer {
      padding-top: 20px;
      padding-bottom: 20px;
      background-color: #CBC7BF;
      color: #004165;
  }
  /*.footer {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
  }*/
  .mainnav {
  
      padding-top:15px;
      float:right;
      position:static;
  }
  .welcome {
      border-left:2px solid #cbc7bf;
      width:240px;
      text-align:center;
      text-decoration-color:transparent;
      
  }
  .welcome a {
      text-decoration:none;
  }
  
  .welcomedd {
      float:right; 
      padding-top:13px; 
      padding-right:5px; 
  }
  #welcome {
      text-decoration:none;
      font-size:20px;
      font-family:helvetica;
      
  }
  #welcomeuser{
      height:24px;
      width:24px;
  }
  .header {
      height:70px;
      background-color:#BAB7B2;
      z-index:5000;
  }
  .subheader {
      height:45px; 
      background-color:#004165;
      position:absolute;
      z-index:100;
  }
  ul.nav a {
      color: white ; /*!important*/
  }
  
      ul.nav a:hover {
          color: #004165 !important;
      }
  .navbar {
      /*z-index:14500;*/
      background-color:transparent; 
  }
  .navbar-inverse {
      z-index:14500;
      border-color:#3cb6CE;
      }
  .navbar-inverse .navbar-nav > .active > a {
      /*z-index:15000;*/
      color: #004165 !important;
      /*background-color: #C5C3C3 !important;*/
      /*border: 2px solid ridge;
      border-radius: 10px;*/
  }
  .TOPSlogo {
      padding-top:10px;
      width:350px;
      /*margin-left:-21px*/
  }
  .logotext {
      color:#004165;
      margin-top:2px;
  }
  .logindiv { 
      height:100%; 
      float:right;
      
      
  }
  
  .opacity-style {
      opacity: 0.40;
  }
  
  .divTop-border {
      border-top: 6px solid #FC9370;
      background: rgba(197, 195, 195, 0.14);
  }
  
  .divTop-border-empty {
      border-top: 6px solid #0294B5;
  }
  
  .divTop-border-empty1 {
      border-top: 1px solid #0294B5;
  }
  
  .row {
      margin: 0px !important;
  }
  
  .aLink-style {
      color: #FC5D2B;
  }
  
  .btn:hover
   {
      /*padding:inherit;
      padding: 6px;
      stroke-width:2px;*/
      border-color:#004165;
      background-color: white;
      text-decoration: none;
      color:black;
      /*font-size:inherit;*/
  }
  
  /*.btn-site {
      padding:inherit;
      height: 28px;
      border-radius: 4px;
      background-color: #3cb6ce;
      font-size:inherit;
      padding-left: 25px;
      padding-right: 25px;        
      margin: 10px;
  }*/
  
  .btn-primary {
      background-color: #004165;
      border-color: #004165;
  }
  
  .btn-site {
      /*changes*/
      font-size: 25px;
      /*padding-left: 25px;
      padding-right: 25px;*/    
      padding:6px ;
      background-color:#004165;
      border-color:#004165;
      height:49px;
      border-radius:4px;
      outline-width:2px;
      color:white;
  }
  .btn.disabled,
  .btn[disabled],
  fieldset[disabled] {
      background-color:#cbc7bf;
      border-color:transparent;
  }
  
  
  /*.btn:hover {
      color: black;
      text-decoration: none;
      background-color: white;
      border-color: #3CB6CE;
  }*/
  .btn:focus {
      color: black;
      text-decoration: none;
      background-color: white;
      border-color: #004165;
  }
  
  .divcolor {
      /*background-color:#004165;*/
      color:#ff6218;
  }
  
  .btn-margin {
      margin-left: 10px !important;
  }
  
  
   .btn-responsive {
          white-space: normal !important;
          word-wrap: break-word;
          margin: 10px;
      }
  
  .custom-modal-header {
      background: #004165;
      color: #fff;
      border-color:#004165;
      margin-top:-2px;
      border-top-left-radius:5px;
      border-top-right-radius:5px;
  }
  
  .custom-modal-header .modal-title {
          color: #fff;
          /*font-weight:bold;*/
  }
  .modal-header .close {
      color:white;
  }
  
  
  /*.custom-modal-header .close {
          float: right !important;
          margin-right: -30px !important;
          margin-top: -30px !important;
          background-color: white !important;
          border-radius: 15px !important;
          width: 30px !important;
          height: 30px !important;
          opacity: 1 !important;
      }*/
  
  /*.custom-modal form {
      max-width: 600px;
  }*/
  .loading {
      position: fixed;
      left: 0;
      bottom: 0;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #FFF;
      background-image: url('assets/Images/Ajax Loader.gif');
      background-repeat: no-repeat;
      background-position: center;
      z-index: 100;
      opacity: 0.4;
      filter: alpha(opacity=40); /* For IE8 and earlier */
  }
  
  
  /*select {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20fill%3D%22%23555555%22%20%0A%09%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%22-261%20145.2%2024%2024%22%20style%3D%22enable-background%3Anew%20-261%20145.2%2024%2024%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cpath%20d%3D%22M-245.3%2C156.1l-3.6-6.5l-3.7%2C6.5%20M-252.7%2C159l3.7%2C6.5l3.6-6.5%22%2F%3E%0A%3C%2Fsvg%3E");
    padding-right: 25px;
    background-repeat: no-repeat;
    background-position: right center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  
  select::-ms-expand {
    display: none;
  }*/
  
  .row.no-gutter {
  margin-left:0;
  margin-right:0;
  }
      .row.no-gutter [class*='col-']:not(:first-child),
      .row.no-gutter [class*='col-']:not(:last-child) {
      padding-right:0;
      padding-left:0;
      }
  .sweet-alert {
      background-color: white !important;
      font-family: Calibri !important;
  }
  
      .sweet-alert button {
          background-color: #004165 !important;
      }
  
          .sweet-alert button:hover {
              /*background-color: #3276b1 !important;*/
              background-color: white !important;
              color:black;
              border: 1px #004165 solid;
          }
  
  .required-field {
      color: red;
  }
  
  .disp-Inline {
      display: inline-block !important;
  }
  
  .navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a:focus {
      background-color: transparent ;
  }
   .minPadding-top {
          padding-top: 5px !important;
      }
   .bootstrap-datetimepicker-widget .datepicker-years thead .picker-switch {
       cursor: default;
       pointer-events: none;
  }
    .bootstrap-datetimepicker-widget .datepicker-months thead .picker-switch {
       cursor: default;
       pointer-events: none;
  }
    .bootstrap-datetimepicker-widget .datepicker-days thead .picker-switch {
       cursor: default;
       pointer-events: none;
  }
  .bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover {
      background-color: #3cb6ce;
  }
  .bootstrap-select.btn-group .dropdown-menu li a:focus {
       background-color:#004165;
       /*border-color: #004165;*/
  }
  .bootstrap-select.btn-group .dropdown-menu li a:hover {
       background-color:#004165;
       /*border-color: #004165;*/
  }
  .bootstrap-select.btn-group :hover, .bootstrap-select.btn-group :active {
       border-color:#66afe9;
       box-shadow:#66afe9;
  }
  /*Hyper Links*/
  /*a {
      color:#004165;
  
  }*/
   
  /*Hover DropDown*/
  
  /*.sidedropdown {
      float: left;
      overflow: hidden;
      position:relative;
      padding-top:14px;
  }*/
  
  .sidedropdown .dropbtn {
     
      background-color:transparent;
      border-color:transparent;
  }
   .sidedropdown:hover .dropbtn {
      background-color: transparent;
  }
  
  
  .dropdown-content {
      display: none;
      /*position: absolute;*/
      padding-top:20px;
      float:left;
      position:fixed;
      /*overflow:auto;*/
      background-color: #3cb6ce;
      min-width: 215px;
      /*min-height:100%;*/
      /*display:compact;*/
      right:0;
      /*box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);*/
      z-index: 1;
  }
  
  .dropdown-content a {
      float: none;
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      text-align: left;
  }
  
  .dropdown-content a:hover {
      background-color:transparent;
  }
  
  .sidedropdown:hover .dropdown-content {
      display:block;
      position:fixed;
      right:0;
  }
  
  /*User Drop Down*/
  .userdropdown {
      overflow:hidden;
      position:relative;
      display: inline-block;
  }
  .userbtn {
      background-color:transparent;
      border-color:transparent;
  }
  
  .userdropdown-content {
      padding-top:20px;
      float:left;
      position:fixed;
      /*overflow:auto;*/
      background-color: #3cb6ce;
      min-width: 215px;
      display:compact;
      right:0;
      /*box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);*/
      z-index: 1;
  }
  
  .userdropdown-content a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
  }
  .userdropdown-content a:hover {background-color: transparent;}
  
  .userdropdown:hover, .userdropdown-content {
      display:block;
  }
  /*.userbtn:hover, .userbtn:focus {
      background-color: transparent;
  }*/
  .userdropdown:hover .dropbtn {
      
      background-color: transparent;
  }
  /*User Drop Down Ends*/
  
  /*Side Menu*/
  
  
    /*Landing page side menu*/
  #opt-slide {
      background-color:transparent;
      width:auto;
      font-size:16px;
      
  }
  #toggle {
      display:block;
      color:#CBC7BF;
  }
  #dropdown {
      position:relative;
      min-height:100%;
      /*max-height:100%;*/
      padding-top:20px;
      width:250px;
      background:#004165;
      color:white;
      display:none;
  }
  /*#dropdown li {
      height:auto;
      /*line-height:40px;
  }*/
  /*#dropdown li:hover {
      background:#FAFAFA;
  }*/
  
  .sidenavigation {
    background-color:transparent;
    
  }
  
  /* reset our lists to remove bullet points and padding */
  .sidemainmenu, .sidesubmenu {
    list-style: none;
    /*height:auto;*/
    color:white;
    padding: 0;
    margin: 0;
  }
  
  /* make ALL links (main and submenu) have padding and background color */
  .sidemainmenu a {
    display: block;
    background-color: #004165;
    color:white;
    text-decoration: none;
    padding:10px;
    
  }
  
  /* add hover behaviour */
  .sidemainmenu a:hover {
      border:2px solid;
      border-color:#004165;
      background-color:white;
      color:#ff6218;
      border-radius:6px;
  }
  
  
  /* when hovering over a .mainmenu item,
    display the submenu inside it.
    we're changing the submenu's max-height from 0 to 200px;
  */
  
  /*.sidemainmenu li:hover .sidesubmenu {
    display: block;
    max-height: 200px;
    color:#004165;
  }*/
  .sidesubmenu {
    display: none;
    max-height: 200px;
    color:#004165;
  }
  
  /*
    we now overwrite the background-color for .submenu links only.
    CSS reads down the page, so code at the bottom will overwrite the code at the top.
  */
  
  .sidesubmenu a {
     padding-left:35px;
    background-color:#004165;
    color:white;
  }
  
  /* hover behaviour for links inside .submenu */
  .sidesubmenu a:hover {
    background-color: white;
    color:#ff6218;
  }
  
  /* this is the initial state of all submenus.
    we set it to max-height: 0, and hide the overflowed content.
  */
  /*.sidesubmenu {
    overflow: hidden;
    max-height: 0;
    -webkit-transition: all 0.5s ease-in;
    -webkit-transition-delay:0.30s;
  }*/
  
  .navbar-collapse.in {
      background-color:#BAB7B2;
      position:inherit;
      /*z-index:10000;*/
      border-color:transparent;
      right:0;
      position:absolute;
  }
  
  .navbar-nav > li > .navdropdown {
     
      background-color:#BAB7B2;
      width:auto;
      right:0;
      border:0;
     
  }
  .navbar-nav > .open > .a:focus {
      background-color:transparent;
  }
  .navbar-nav > li > a:hover, .navbar-nav > li > a:focus {
      background-color:transparent;
      width:100%
  }

  /* .navbar-nav > li > span > a:hover, .navbar-nav > li > span > a:focus {
    background-color:gray;
    border:2px solid;
    border-radius:6px;
    border-color:#004165;   
} */

  /*.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a:focus {
      background-color:transparent;
  }*/
  .dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
      background-color:transparent;
  }
  .navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus {
      background-color:transparent;
  }
  
  /**/
  .navdropdown > li > a:hover, .navdropdown > li > a:focus {
      background-color:transparent;
  }
  .navbar-inverse {
      border-color:transparent;
  }
  .navfont {
      font-size:16px; 
      font-weight:bold;
      z-index:101;
  }
  .navdropdown > li > a {
      min-height:40px;
      font-size:16px; 
      font-weight:bold;
      text-align:left;
  }
  .navbar-inverse .navbar-toggle:hover, .navbar-inverse .navbar-toggle:focus {
      background-color:transparent;
      border-color:transparent;
      z-index:50000;
  }
  .sidebarbtn {
      background-color:transparent;
      margin-top:6px; 
      border-color:transparent;
      /*font-size:16px;*/
  
  }
  /*Side menu ends*/
  
  /*Pagination Colors*/
  
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
      background-color:#004165;
  }
  
  .subheaderlogin{
      height:34px;
  }
  .menulinktext span, .menulinktext strong, .alink{
      color:#ff6218;
  }
  .menulinktext > a {
      background-color:white;
      border:2px solid;
      border-radius:6px;
      border-color:#004165;
  }
  .alink >a{
      color: #ff6218;
  }
  [ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
  }
  .btn-info {
      background-color:#004165;
      color:white;
      border-color:#004165;
  }
  .panel-info {
      border-color:#e8e6df;
  }
  .panel-info > .panel-heading {
      background-color:#e8e6df;
  }
  .panel-info > .panel-heading:hover {
      background-color:#eff1f2;
  }
  
  
  /*      ---------------------------------*/
  
  
  .col-xs-1,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  .col-xs-1,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9,
  .col-xs-10,
  .col-xs-11 {
    float: left;
  }
  
  .col-xs-1 {
    width: 8.333333333333332%;
  }
  
  .col-xs-2 {
    width: 16.666666666666664%;
  }
  
  .col-xs-3 {
    width: 25%;
  }
  
  .col-xs-4 {
    width: 33.33333333333333%;
  }
  
  .col-xs-5 {
    width: 41.66666666666667%;
  }
  
  .col-xs-6 {
    width: 50%;
  }
  
  .col-xs-7 {
    width: 58.333333333333336%;
  }
  
  .col-xs-8 {
    width: 66.66666666666666%;
  }
  
  .col-xs-9 {
    width: 75%;
  }
  
  .col-xs-10 {
    width: 83.33333333333334%;
  }
  
  .col-xs-11 {
    width: 91.66666666666666%;
  }
  
  .col-xs-12 {
    width: 100%;
  }
  
  @media (min-width: 768px) {
    .container {
      max-width: 750px;
    }
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11 {
      float: left;
    }
    .col-sm-1 {
      width: 8.333333333333332%;
    }
    .col-sm-2 {
      width: 16.666666666666664%;
    }
    .col-sm-3 {
      width: 25%;
    }
    .col-sm-4 {
      width: 33.33333333333333%;
    }
    .col-sm-5 {
      width: 41.66666666666667%;
    }
    .col-sm-6 {
      width: 50%;
    }
    .col-sm-7 {
      width: 58.333333333333336%;
    }
    .col-sm-8 {
      width: 66.66666666666666%;
    }
    .col-sm-9 {
      width: 75%;
    }
    .col-sm-10 {
      width: 83.33333333333334%;
    }
    .col-sm-11 {
      width: 91.66666666666666%;
    }
    .col-sm-12 {
      width: 100%;
    }
    .col-sm-push-1 {
      left: 8.333333333333332%;
    }
    .col-sm-push-2 {
      left: 16.666666666666664%;
    }
    .col-sm-push-3 {
      left: 25%;
    }
    .col-sm-push-4 {
      left: 33.33333333333333%;
    }
    .col-sm-push-5 {
      left: 41.66666666666667%;
    }
    .col-sm-push-6 {
      left: 50%;
    }
    .col-sm-push-7 {
      left: 58.333333333333336%;
    }
    .col-sm-push-8 {
      left: 66.66666666666666%;
    }
    .col-sm-push-9 {
      left: 75%;
    }
    .col-sm-push-10 {
      left: 83.33333333333334%;
    }
    .col-sm-push-11 {
      left: 91.66666666666666%;
    }
    .col-sm-pull-1 {
      right: 8.333333333333332%;
    }
    .col-sm-pull-2 {
      right: 16.666666666666664%;
    }
    .col-sm-pull-3 {
      right: 25%;
    }
    .col-sm-pull-4 {
      right: 33.33333333333333%;
    }
    .col-sm-pull-5 {
      right: 41.66666666666667%;
    }
    .col-sm-pull-6 {
      right: 50%;
    }
    .col-sm-pull-7 {
      right: 58.333333333333336%;
    }
    .col-sm-pull-8 {
      right: 66.66666666666666%;
    }
    .col-sm-pull-9 {
      right: 75%;
    }
    .col-sm-pull-10 {
      right: 83.33333333333334%;
    }
    .col-sm-pull-11 {
      right: 91.66666666666666%;
    }
    .col-sm-offset-1 {
      margin-left: 8.333333333333332%;
    }
    .col-sm-offset-2 {
      margin-left: 16.666666666666664%;
    }
    .col-sm-offset-3 {
      margin-left: 25%;
    }
    .col-sm-offset-4 {
      margin-left: 33.33333333333333%;
    }
    .col-sm-offset-5 {
      margin-left: 41.66666666666667%;
    }
    .col-sm-offset-6 {
      margin-left: 50%;
    }
    .col-sm-offset-7 {
      margin-left: 58.333333333333336%;
    }
    .col-sm-offset-8 {
      margin-left: 66.66666666666666%;
    }
    .col-sm-offset-9 {
      margin-left: 75%;
    }
    .col-sm-offset-10 {
      margin-left: 83.33333333333334%;
    }
    .col-sm-offset-11 {
      margin-left: 91.66666666666666%;
    }
  }
  
  @media (min-width: 992px) {
    .container {
      max-width: 970px;
    }
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11 {
      float: left;
    }
    .col-md-1 {
      width: 8.333333333333332%;
    }
    .col-md-2 {
      width: 16.666666666666664%;
    }
    .col-md-3 {
      width: 25%;
    }
    .col-md-4 {
      width: 33.33333333333333%;
    }
    .col-md-5 {
      width: 41.66666666666667%;
    }
    .col-md-6 {
      width: 50%;
    }
    .col-md-7 {
      width: 58.333333333333336%;
    }
    .col-md-8 {
      width: 66.66666666666666%;
    }
    .col-md-9 {
      width: 75%;
    }
    .col-md-10 {
      width: 83.33333333333334%;
    }
    .col-md-11 {
      width: 91.66666666666666%;
    }
    .col-md-12 {
      width: 100%;
    }
    .col-md-push-0 {
      left: auto;
    }
    .col-md-push-1 {
      left: 8.333333333333332%;
    }
    .col-md-push-2 {
      left: 16.666666666666664%;
    }
    .col-md-push-3 {
      left: 25%;
    }
    .col-md-push-4 {
      left: 33.33333333333333%;
    }
    .col-md-push-5 {
      left: 41.66666666666667%;
    }
    .col-md-push-6 {
      left: 50%;
    }
    .col-md-push-7 {
      left: 58.333333333333336%;
    }
    .col-md-push-8 {
      left: 66.66666666666666%;
    }
    .col-md-push-9 {
      left: 75%;
    }
    .col-md-push-10 {
      left: 83.33333333333334%;
    }
    .col-md-push-11 {
      left: 91.66666666666666%;
    }
    .col-md-pull-0 {
      right: auto;
    }
    .col-md-pull-1 {
      right: 8.333333333333332%;
    }
    .col-md-pull-2 {
      right: 16.666666666666664%;
    }
    .col-md-pull-3 {
      right: 25%;
    }
    .col-md-pull-4 {
      right: 33.33333333333333%;
    }
    .col-md-pull-5 {
      right: 41.66666666666667%;
    }
    .col-md-pull-6 {
      right: 50%;
    }
    .col-md-pull-7 {
      right: 58.333333333333336%;
    }
    .col-md-pull-8 {
      right: 66.66666666666666%;
    }
    .col-md-pull-9 {
      right: 75%;
    }
    .col-md-pull-10 {
      right: 83.33333333333334%;
    }
    .col-md-pull-11 {
      right: 91.66666666666666%;
    }
    .col-md-offset-0 {
      margin-left: 0;
    }
    .col-md-offset-1 {
      margin-left: 8.333333333333332%;
    }
    .col-md-offset-2 {
      margin-left: 16.666666666666664%;
    }
    .col-md-offset-3 {
      margin-left: 25%;
    }
    .col-md-offset-4 {
      margin-left: 33.33333333333333%;
    }
    .col-md-offset-5 {
      margin-left: 41.66666666666667%;
    }
    .col-md-offset-6 {
      margin-left: 50%;
    }
    .col-md-offset-7 {
      margin-left: 58.333333333333336%;
    }
    .col-md-offset-8 {
      margin-left: 66.66666666666666%;
    }
    .col-md-offset-9 {
      margin-left: 75%;
    }
    .col-md-offset-10 {
      margin-left: 83.33333333333334%;
    }
    .col-md-offset-11 {
      margin-left: 91.66666666666666%;
    }
  }
  
  @media (min-width: 1200px) {
    .container {
      max-width: 1170px;
    }
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11 {
      float: left;
    }
    .col-lg-1 {
      width: 8.333333333333332%;
    }
    .col-lg-2 {
      width: 16.666666666666664%;
    }
    .col-lg-3 {
      width: 25%;
    }
    .col-lg-4 {
      width: 33.33333333333333%;
    }
    .col-lg-5 {
      width: 41.66666666666667%;
    }
    .col-lg-6 {
      width: 50%;
    }
    .col-lg-7 {
      width: 58.333333333333336%;
    }
    .col-lg-8 {
      width: 66.66666666666666%;
    }
    .col-lg-9 {
      width: 75%;
    }
    .col-lg-10 {
      width: 83.33333333333334%;
    }
    .col-lg-11 {
      width: 91.66666666666666%;
    }
    .col-lg-12 {
      width: 100%;
    }
    .col-lg-push-0 {
      left: auto;
    }
    .col-lg-push-1 {
      left: 8.333333333333332%;
    }
    .col-lg-push-2 {
      left: 16.666666666666664%;
    }
    .col-lg-push-3 {
      left: 25%;
    }
    .col-lg-push-4 {
      left: 33.33333333333333%;
    }
    .col-lg-push-5 {
      left: 41.66666666666667%;
    }
    .col-lg-push-6 {
      left: 50%;
    }
    .col-lg-push-7 {
      left: 58.333333333333336%;
    }
    .col-lg-push-8 {
      left: 66.66666666666666%;
    }
    .col-lg-push-9 {
      left: 75%;
    }
    .col-lg-push-10 {
      left: 83.33333333333334%;
    }
    .col-lg-push-11 {
      left: 91.66666666666666%;
    }
    .col-lg-pull-0 {
      right: auto;
    }
    .col-lg-pull-1 {
      right: 8.333333333333332%;
    }
    .col-lg-pull-2 {
      right: 16.666666666666664%;
    }
    .col-lg-pull-3 {
      right: 25%;
    }
    .col-lg-pull-4 {
      right: 33.33333333333333%;
    }
    .col-lg-pull-5 {
      right: 41.66666666666667%;
    }
    .col-lg-pull-6 {
      right: 50%;
    }
    .col-lg-pull-7 {
      right: 58.333333333333336%;
    }
    .col-lg-pull-8 {
      right: 66.66666666666666%;
    }
    .col-lg-pull-9 {
      right: 75%;
    }
    .col-lg-pull-10 {
      right: 83.33333333333334%;
    }
    .col-lg-pull-11 {
      right: 91.66666666666666%;
    }
    .col-lg-offset-0 {
      margin-left: 0;
    }
    .col-lg-offset-1 {
      margin-left: 8.333333333333332%;
    }
    .col-lg-offset-2 {
      margin-left: 16.666666666666664%;
    }
    .col-lg-offset-3 {
      margin-left: 25%;
    }
    .col-lg-offset-4 {
      margin-left: 33.33333333333333%;
    }
    .col-lg-offset-5 {
      margin-left: 41.66666666666667%;
    }
    .col-lg-offset-6 {
      margin-left: 50%;
    }
    .col-lg-offset-7 {
      margin-left: 58.333333333333336%;
    }
    .col-lg-offset-8 {
      margin-left: 66.66666666666666%;
    }
    .col-lg-offset-9 {
      margin-left: 75%;
    }
    .col-lg-offset-10 {
      margin-left: 83.33333333333334%;
    }
    .col-lg-offset-11 {
      margin-left: 91.66666666666666%;
    }
  }
  

  ::ng-deep .mat-drawer-container {
    background-color: #ffffff;
  }


.dp {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background:#eee;
  font-size: 20px;
  width: 25px;
  height: 2px;
  padding-top: 6px;
  padding-bottom: 4px;
  
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-thumb {
  background: #8d8a8a;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::ng-deep .mat-checkbox-ripple .mat-ripple-element {
  background-color: #0075FF !important;
}

::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #0075FF !important;
}

::ng-deep .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  border-radius: 5px !important;
  background-color: #0075FF !important;
}


.container-fluid {
  background-color: white !important;
}
